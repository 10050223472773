$green: #6a8a73;
$light-green: #587074;
$dark-green: #32444e;
$dark-green-opacity: #42545e3a;
$dark-green-screen: #32444ed7;

$dark-blue: #202024;
$blue: #14517b;
$light-blue: #b9c4d5;
$gray-white: #e2e5ec;
