.about-me {
  text-align: left;
  margin-bottom: 20px;
  p {
    text-align: justify;
    position: relative;
    z-index: 5;
    font-size: 1rem;
    color: $gray-white;
  }
}
