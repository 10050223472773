.projects-container {
  margin-top: 8vh;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  .project-card {
    padding: 15px;
    border-radius: 8px;
    .projects-images {
      display: grid;
      grid-template-columns: 3.5fr 2fr;
      gap: 5px;
      margin-bottom: 5px;
      .main-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        border-radius: 8px;
      }
      .small-images {
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        gap: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          border-radius: 8px;
        }
      }
    }
    h3 {
      font-size: 1.6rem;
      color: $gray-white;
      font-weight: 500;
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 10px;
      gap: 5px;
      span {
        width: auto;
        font-size: 0.8rem;
        display: inline-block;
        background-color: $light-green;
        color: $dark-blue;
        padding: 5px 10px;
        border-radius: 30px;
        animation: all 0.5s ease;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    p {
      margin: 5px 0;
      font-size: 1rem;
      color: $light-blue;
    }
    .project-links {
      padding: 10px 0;
      a {
        padding: 6px 16px;
        font-size: 1rem;
        border: none;
        border-radius: 30px;
        font-weight: 500;
        &:first-of-type {
          transition: all 0.5s ease;
          background-color: $dark-blue;
          color: $gray-white;
          &:hover {
            background-color: $light-green;
          }
        }
        &:last-of-type {
          padding: 6px 16px;
          font-size: 1rem;
          border: none;
          border-radius: 30px;
          font-weight: 500;
          background: none;
          color: $gray-white;
          transition: all 0.5s ease;
          &:hover {
            background-color: $gray-white;
            color: $dark-blue;
          }
        }
      }
    }
  }
  .info {
    border: 1.2px dashed $light-blue;
    margin-top: 20px;
    padding: 20px;
    border-radius: 30px;
    color: $gray-white;
    h2 {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .mail-btn {
      margin-top: 20px;
      margin-left: 0 !important;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000db;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    position: relative;
    max-width: 80%;
    .modal-image {
      max-width: 100%;
      max-height: 100%;
      border-radius: 8px;
    }
    .close-button,
    .prev-button,
    .next-button {
      position: absolute;
      background: none;
      border: none;
      color: $gray-white;
      font-size: 1.4rem;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
    }
    .close-button {
      top: 25px;
      right: 10px;
    }

    .prev-button {
      left: 10px;
    }

    .next-button {
      right: 10px;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */
@media only screen and (min-width: 650px) {
  .modal {
    .modal-content {
      position: relative;
      max-width: 60%;
    }
  }
}
/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .projects-container {
    .project-card {
      .projects-images {
        display: grid;
        grid-template-columns: 4.5fr 2fr;
        gap: 5px;
        .main-image {
          width: 100%;
        }
        .small-images {
          width: 100%;
        }
      }
    }
  }
  .modal {
    .modal-content {
      position: relative;
      max-width: 40%;
    }
  }
}
