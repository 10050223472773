.project-box {
  width: 100%;
  position: relative;
  transition: transform 0.3s ease;
  border-radius: 15px;
  overflow: hidden;
  img {
    width: 100%;
    border-radius: 15px;
  }
  .projects-btns {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease;
    gap: 15px;
    &:hover {
      background-color: $dark-green-screen;
    }

    .button {
      padding: 6px 16px;
      font-size: 1rem;
      border: none;
      border-radius: 30px;
      font-weight: 500;
    }
    .button {
      &:first-of-type {
        transition: all 0.5s ease;
        background-color: $dark-blue;
        color: $gray-white;
        &:hover {
          background-color: $light-green;
        }
      }
    }
    .button-link {
      padding: 6px 16px;
      font-size: 1rem;
      border: none;
      border-radius: 30px;
      font-weight: 500;
      background: none;
      color: $gray-white;
      transition: all 0.5s ease;
      &:hover {
        background-color: $gray-white;
        color: $dark-blue;
      }
    }
  }
}
