#navigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
  padding: 30px 20px;
  z-index: 10;
  transition: all 0.5s;
}

.nav-links {
  list-style: none;

  li {
    text-align: start;
    margin: 20px 0;
    a {
      margin-left: 30px;
      color: #fff;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 300;
      list-style: none;
      transition: all 0.5s;
      &:hover {
        padding-left: 5px;
        color: $light-green;
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 15;
  .bar {
    display: block;
    border-radius: 5px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    /*  */
    &:nth-child(1) {
      width: 35px;
      height: 2.5px;
    }

    &:nth-child(2) {
      margin-right: 0;
      width: 30px;
      height: 2.5px;
    }
    &:nth-child(3) {
      margin-right: 0;
      width: 25px;
      height: 2.5px;
    }
  }
}

.hamburger {
  display: block;
  z-index: 100;
}
.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  width: 20px;
  transform: translateY(5px) translateX(5px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
  width: 20px;
  transform: translateY(-12px) rotate(-45deg);
}

.nav-links {
  position: fixed;
  right: -1000%;
  top: 0;
  height: 500px;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  background-color: $dark-green;
  width: 60%;
  box-shadow: 2px 5px 10px #1d1e1e75;
  text-align: center;
  transition: 0.3s;
  padding: 30px 20px;
  z-index: 99;

  ul {
    margin: 30px 0;
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-left: 30px;
  a {
    font-size: 1.6rem;
    color: #fff;
    text-decoration: none;
    i {
      transition: all 0.5s;
      &:hover {
        color: #08d9ff;
      }
    }
  }
}

.nav-links.active {
  right: 0%;
}

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  #navigation {
    padding: 30px 10%;
    .nav-links {
      width: 40%;
    }
  }
}
