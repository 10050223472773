.skills {
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;

  .skill-box {
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;

    h5 {
      margin-top: 8px;
      font-size: 0.6rem;
      font-weight: 500;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    i {
      font-size: 1.5rem;
      transition: transform 0.3s ease;
    }

    &:hover i {
      transform: scale(1.2);
    }

    &:hover h5 {
      opacity: 1;
    }

    .devicon-html5-plain {
      color: #e34f26;
    }
    .devicon-html5-plain + h5 {
      color: #e34f26;
    }

    .devicon-css3-plain {
      color: #1572b6;
    }
    .devicon-css3-plain + h5 {
      color: #1572b6;
    }
    .devicon-c-plain {
      color: #6a9dd3;
    }
    .devicon-c-plain + h5 {
      color: #6a9dd3;
    }

    .devicon-sass-original {
      color: #cc6699;
    }
    .devicon-sass-original + h5 {
      color: #cc6699;
    }

    .devicon-bootstrap-plain {
      color: #7952b3;
    }
    .devicon-bootstrap-plain + h5 {
      color: #7952b3;
    }

    .devicon-javascript-plain {
      color: #f7df1e;
    }
    .devicon-javascript-plain + h5 {
      color: #f7df1e;
    }

    .devicon-react-original {
      color: #61dafb;
    }
    .devicon-react-original + h5 {
      color: #61dafb;
    }

    .devicon-firebase-plain {
      color: #ffca28;
    }
    .devicon-firebase-plain + h5 {
      color: #ffca28;
    }

    .devicon-cloudflare-plain {
      color: #f48120;
    }
    .devicon-cloudflare-plain + h5 {
      color: #f48120;
    }

    .devicon-github-original {
      color: #181717;
    }
    .devicon-github-original + h5 {
      color: #181717;
    }

    .devicon-vscode-plain {
      color: #007acc;
    }
    .devicon-vscode-plain + h5 {
      color: #007acc;
    }

    .devicon-photoshop-plain {
      color: #31a8ff;
    }
    .devicon-photoshop-plain + h5 {
      color: #31a8ff;
    }

    .devicon-canva-original {
      color: #00c4cc;
    }
    .devicon-canva-original + h5 {
      color: #00c4cc;
    }
  }
}
/* ---------------MEDIA QUERIES--------------- */

/* TABLET */
@media only screen and (min-width: 500px) {
  .skills {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* TABLET */
@media only screen and (min-width: 800px) {
  .skills {
    grid-template-columns: repeat(7, 1fr);
  }
}
/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .skills {
    grid-template-columns: repeat(13, 1fr);
  }
}
