.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 5%;
  position: relative;

  img {
    border-radius: 50%;
    width: 20%;
    margin: 10px 0;
  }
  h2 {
    color: $gray-white;
  }
  p {
    margin: 20px 0;
    color: $light-blue;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .error-page {
    img {
      width: 15%;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .error-page {
    img {
      width: 10%;
    }
  }
}
