footer {
  width: 100%;
  margin: 10px auto 0px;
  padding: 40px 30px 10px;
  background-color: $dark-green;
  .footer-links {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-size: 1.2rem;
    .link {
      text-decoration: none;
      color: #ddd;
      font-size: 1rem;
      margin-right: 5px;
      transition: all 0.5s;
      &:hover {
        color: #aaa;
        padding-left: 2px;
      }
    }
  }
  p {
    color: $light-blue;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 25px;
    span {
      width: 100%;
      font-size: 1rem;
    }
  }
  .social-link {
    font-size: 1rem;
    margin-right: 10px;
    width: fit-content;
    color: $gray-white;
    transition: all 0.5s;
    i {
      font-size: 1rem;
      margin: 0;
      margin-right: 5px;
      color: $gray-white;
    }
    &:hover {
      color: $dark-blue;
    }
  }
}

/* TABLET */
@media only screen and (min-width: 650px) and (max-width: 1023px) {
  footer {
    padding: 10%;
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  footer {
    padding: 40px 25% 10px;
  }
}
