main {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  .left-side {
    color: #fff;
    height: 100%;
    width: 100%;
    position: relative;
    .name-box {
      position: absolute;
      top: 10vh;
      left: 30px;
      text-align: left;
      font-family: sans-serif;
      width: fit-content;

      h5 {
        font-size: 0.9rem;
        font-weight: 600;
      }
      h1 {
        margin-top: 25px;
        font-size: 2.4rem;
        font-weight: 600;
      }
      h3 {
        margin-top: -5px;
        font-size: 1rem;
        font-weight: 500;
        color: $gray-white;
      }
      a {
        margin-top: 25px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        padding-top: 5px;
        color: #fff;
        background-color: #ffffff75;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
  }
  .right-side {
    width: 100%;
    position: relative;
    .profile-img-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 250px;
      border-radius: 50%;
      &::before {
        content: "";
        position: absolute;
        left: 52%;
        top: 48%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 200px;
        height: 200px;
        border: 6px solid $gray-white;
        z-index: 2;
      }
      &::after {
        content: "";
        position: absolute;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 180px;
        height: 180px;
        border: 5px solid $light-blue;
        z-index: 3;
      }
      .profile-photo {
        position: absolute;
        left: 50%;
        top: 45.5%;
        transform: translate(-50%, -50%);
        z-index: 4;
        width: 205px;
        height: 205px;
        object-fit: cover;
        object-position: center;
        border-radius: 100%;
      }
    }
  }
}

/* MINI TABLET */
@media only screen and (min-width: 650px) {
  main {
    height: 70vh;
    .left-side {
      .name-box {
        left: 15%;
        h5 {
          font-size: 1.1rem;
        }
        h1 {
          font-size: 2.6rem;
        }
        h3 {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* TABLET */
@media only screen and (min-width: 850px) {
  main {
    width: 90%;
    margin: 0 auto 30px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 55vh;
    gap: 10px;
    position: relative;

    .left-side {
      .name-box {
        position: relative;
        width: 100%;

        h1 {
          font-size: 2.6rem;
        }
        h3 {
          font-size: 1.2rem;
        }
        a {
          font-size: 1.4rem;
        }
      }
    }
    .right-side {
      .profile-img-box {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::before {
          top: 54%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 250px;
          height: 250px;
        }
        &::after {
          top: 59.6%;
          left: 45%;
          width: 206px;
          height: 206px;
        }
        .profile-photo {
          top: 55%;
          left: 45%;
          width: 235px;
          height: 235px;
        }
      }
    }
  }
}
/* *****BIG DESKTOP***** */
@media only screen and (min-width: 1024px) {
  main {
    width: 100%;
    margin: 0 auto;
    .left-side {
      .name-box {
        h5 {
          font-size: 1.4rem;
        }
        h1 {
          font-size: 2.9rem;
        }
        h3 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
