* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}

body {
  background-color: $dark-green;
}

.title-line {
  margin-top: 0px;
  width: 60px;
  height: 5px;
  background-color: $light-green;
  margin-bottom: 20px;
}
.line {
  margin-top: 15px;
  width: 50px;
  height: 2px;
  background-color: $light-green;
}

.mail-btn {
  font-size: 1rem;
  margin-left: 30px;
  padding: 6px 16px;
  background-color: $light-blue;
  border: none;
  color: $dark-blue;
  border-radius: 30px;
  transition: all 0.5s ease;
  &:hover {
    background-color: $light-green;
  }
}

.section {
  width: 100%;
  padding: 20px;
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    color: $light-blue;
    transition: all 0.5s;
    &:hover {
      padding-left: 4px;
    }
  }

  .more-btn {
    display: inline-block;
    margin: 20px auto;
    padding: 6px 12px;
    background-color: $light-blue;
    border-radius: 30px;
    color: $dark-blue;
  }
}
.grid-2 {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */
@media only screen and (min-width: 650px) {
  .section {
    width: 80%;
    margin: 0 auto;
    padding: 10px 20px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .section {
    width: 60%;
    margin: 0 auto;
    padding: 20px 40px;
  }
  .grid-2 {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
