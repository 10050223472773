.certificates-box {
  color: #ddd;
  text-align: left;
  margin: 20px 0;
  p {
    font-size: 1rem;
    position: relative;
    z-index: 5;
  }
  .link {
    margin-top: 10px;
    font-size: 1.6rem;
    text-decoration: none;
    color: #eee;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    i {
      font-size: 1.6rem;
    }
    &:hover {
      opacity: 0.8;
      color: #1bb5dc;
    }
  }
}
