.privacy {
  margin-top: 8vh;
  .privacy-terms {
    color: #fff;
    margin-top: 40px;
    padding: 30px;
    h4 {
      font-size: 1rem;
      color: $light-blue;
      font-weight: 500;
      margin-bottom: 15px;
    }
    ul {
      padding: 2px 20px 10px;
      font-size: 1rem;
      font-weight: 300;
      color: #ddd;
      margin-bottom: 10px;
    }
    h3 {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 1.2rem;
      font-weight: 500;

      color: $light-blue;
    }
    p {
      font-size: 1rem;
      color: #aaa;
    }
    a {
      font-weight: 400;
      font-size: 1rem;
      color: $light-blue;
      text-decoration: underline;
    }
  }
}
